body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

.header {
  background-color: #343a40;
  color: #ffffff;
  padding: 1rem;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3 {
  color: #343a40;
}

p {
  color: #6c757d;
}

.navbar-nav .nav-link {
  color: #000000;
  padding: 0.5rem 1rem; /* Add padding around the text */
  font-size: 1.5em; 
  margin-right: 15px;
}

.navbar-nav .nav-link:hover {
  background-color: #6c757d; /* Change background color on hover */
  border-radius: 4px; /* Add border radius */
}

/* Custom CSS for active navbar button */
.navbar-nav .nav-link.active {
  color: #ffffff;
  background-color: #007bff; /* Set active button background color (blue) */
  border-radius: 4px; /* Optional: Add border radius to active button */
}

a {
  text-decoration: none;
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2876, 175, 80, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.cert-image {
  width: 100%;
  height: auto;
  max-width: 44em; 
  display: block;
  margin: 0 auto; 
}